import React from "react";
import styled from "styled-components";
import { Breakpoints } from "../../GlobalStyle";

const InputWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    background: transparent;
    border-radius: var(--border-radius-8);
    height: fit-content;
    float: left;
    background-color: var(--surface-0);
    width: ${(props) => (props.width ? props.width : "100%")};
    border: ${(props) => {
        const wrapperBorderValue = props?.hideWrapperBorder ? "none" : "1px solid var(--gray-40)";
        return props.isFocused ? "1px solid orange" : wrapperBorderValue;
    }};
    ${(props) => (props.error ? `border: 1px solid var(--error);` : ``)}
    svg {
        margin: 6px;
        cursor: pointer;
    }

    &:hover {
        input {
            ::placeholder {
                color: var(--gray-80);
            }
        }
    }
`;

const StyledInput = styled.input`
    display: flex;
    flex: 1 1 0%;
    padding: 2px 8px;
    position: relative;
    width: 100%;
    overflow: hidden;
    background: transparent;
    padding: ${({ size }) => size === "small" && "10px 8px"};
    height: ${({ size }) => (size === "large" ? "46px" : "40px")};
    color: ${(props) => (props.disabled ? "var(--gray-60)" : "var(--text-default)")};
    border-radius: var(--border-radius-8);
    font-size: var(--body-2-d);
    font-weight: var(--regular);
    line-height: var(--line-height-21);
    :-webkit-autofill {
        -webkit-text-fill-color: var(--text-default);
        transition: background-color 5000s ease-in-out 0s;
    }
    ::placeholder {
        color: var(--gray-60);
    }
    @media (max-width: ${Breakpoints.mobile}px) {
        font-size: var(--body-2-m);
        height: 40px;
    }
`;

const Label = styled.label`
    display: flex;
    cursor: pointer;
    font-size: var(--body-3-d);
    font-weight: var(--font-weight-400);
    color: var(--text-subdued-1);
`;
Label.Wrapper = styled.div`
    margin-bottom: 8px;
    display: flex;
`;
Label.Required = styled.span`
    margin-left: 4px;
    font-size: var(--body-3-d);
`;

export default function TextInput({
    children,
    label,
    inputLabel,
    type,
    size,
    placeholder,
    register,
    validation,
    testId,
    width,
    error,
    ...restProps
}) {
    return (
        <>
            {inputLabel && (
                <Label disabled={restProps.disabled} htmlFor={restProps.id}>
                    <Label.Wrapper>{inputLabel}</Label.Wrapper>
                    <Label.Required>{restProps?.required && "*"}</Label.Required>
                </Label>
            )}
            <InputWrapper
                hideWrapperBorder={restProps?.hideWrapperBorder}
                error={error}
                width={width}
            >
                <StyledInput
                    size={size}
                    data-testid={testId ? testId : "inputField"}
                    {...(register && register(label, validation))}
                    {...restProps}
                    type={type ? type : "text"}
                    placeholder={placeholder ? placeholder : ""}
                />
                {children}
            </InputWrapper>
        </>
    );
}
