import React from "react";
import styled from "styled-components";

import LoaderIcon from "common/components/LoaderIcon";

const Wrapper = styled.div`
    position: relative;
    ${(props) => (props.stretch ? "height: 100%; width: 100%;" : "")};
    height: ${(props) => (props.height ? props.height : "100%")};
    overflow: ${(props) => (props.overflow ? props.overflow : "hidden")};
`;

const LoaderContainer = styled.div`
    height: ${(props) => (props.height ? props.height : "100%")};
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: ${(props) => (props.transparent ? "transparent" : props.backgroundColor)};
    opacity: ${(props) => (props.opacity ? props.opacity : "0")};
    z-index: ${(props) => (props.zIndex ? props.zIndex : "0")};
`;
const CustomMsg = styled.div`
    color: white;
    font-size: 14px;
`;

export default function BoxLoader({
    loading = false,
    stretch = false,
    transparent = false,
    message,
    opacity = "0",
    backgroundColor = "transparent",
    zIndex = null,
    children,
    ...restProps
}) {
    return (
        <>
            <Wrapper stretch={stretch} {...restProps}>
                {loading && (
                    <LoaderContainer
                        transparent={transparent}
                        opacity={opacity}
                        backgroundColor={backgroundColor}
                        data-testid="loader-container"
                        zIndex={zIndex}
                    >
                        <LoaderIcon />
                        {message && <CustomMsg>{message}</CustomMsg>}
                    </LoaderContainer>
                )}
                {children}
            </Wrapper>
        </>
    );
}
