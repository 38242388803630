import React, { useEffect, useState } from "react";
import { useModal } from "common/components/NewModal";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import AddNewCard from "../BillingAndPayments/BillingDetails/AddNewCard/index";
import { toast } from "react-toastify";
import { Breakpoints } from "../../../../GlobalStyle";
import BackButton from "common/components/BackButton";
import PaymentsService from "services/payments.service";
import { billingConstants } from "../constants";
import Button from "common/components/Button";
import BoxLoader from "common/components/BoxLoader";
import StripeOrderDetails from "./StripeOrderDetails";
import environment from "@src/environment";
import EachCard from "./EachCard";
import { useQuery } from "@common/utils/common-utils";
import BillingAddressForm from "../BillingAddressForm";
import { EventEmitter } from "@common/events/eventEmitter";
import { CHECKOUT_INITIATED, PAYMENT_FAILURE, BUY_BUTTON_CLICK } from "@common/events/events";
import retry from "async-retry";
import useMediaQuery from "@common/components/useMediaQuery";
import ResponsiveLogo from "@common/components/Logo/ResponsiveLogo";

const Heading = styled.div`
    display: flex;
    font-size: var(--subtitle-2-d);
    font-weight: var(--font-weight-600);
    margin: 20px 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 13px;
    color: var(--text-default);
`;

const OrderSummaryWrapper = styled.div`
    height: 100%;
    /* max-width: 400px; */
`;

const PageWrapper = styled.div`
    display: flex;
    position: relative;
    height: 100%;

    @media (max-width: ${Breakpoints.tablet}px) {
        flex-direction: column;
    }
`;

const BillingAddressAndCardDetailsWrapper = styled.div`
    width: ${({ isMobile }) => (isMobile ? "100%" : "65%")};
    overflow: auto;
`;

const PaymentDetailsWrapper = styled.div`
    background-color: var(--surface-0);
    width: 35%;

    @media (max-width: ${Breakpoints.tablet}px) {
        width: 100%;
        background-color: transparent;

        :after {
            content: "";
            height: 5.25rem;
            display: block;
        }
    }
`;

const FormWrapper = styled.div`
    width: ${({ isMobile }) => (isMobile ? "85%" : "65%")};
    margin: ${({ isMobile }) => (isMobile ? "2rem auto" : "5rem auto 0")};

    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 0px;
    }
`;

const PaymentMobileWrapper = styled.div`
    @media (max-width: ${Breakpoints.tablet}px) {
        position: absolute;
        width: 100%;
        bottom: 0px;
        background-color: var(--surface-0);
    }
`;

const PaymentBreakDownWrapper = styled.div`
    width: ${({ isMobile }) => (isMobile ? "85%" : "65%")};
    margin: ${({ isMobile }) => (isMobile ? "0 auto" : "5rem auto 0")};
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 25px 0px 25px;
    width: 100%;
    margin: 10px 0;

    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 0px;
    }
`;

const HeadinWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const ConfirmationWrapper = styled.div`
    padding: 10px 25px 0px 25px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 0px;
    }
`;

const ConfirmationActionBar = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ConfirmationDesc = styled.div`
    font-size: var(--subtitle-1-d);
    font-weight: var(--font-weight-400);

    @media (max-width: ${Breakpoints.mobile}px) {
        font-size: var(--subtitle-2-d);
    }
`;

const StripeCheckoutPage = ({ orgId, selectedItem, itemType, confirmationMsg }) => {
    const isMobile = useMediaQuery(`(max-width: ${Breakpoints.tablet}px)`);
    const [orderView, toggleOrderView] = useState(false);
    const history = useHistory();
    const [paymentStep, updatePaymentState] = useState("billingAddress");
    const query = useQuery();
    const redirectURL = query.get("redirectUrl");
    const utmSource = query.get("utm_source");
    const stripe = useStripe();
    const elements = useElements();
    const [submitting, setSubmitting] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [paymentMessage, setPaymentMessage] = useState();
    const [cardList, setCardsList] = useState([]);
    const [customerAddress, setCustomerAddress] = useState(null);
    const [loadingCustomerAddress, setCustomerAddressLoader] = useState(true);
    const [nonRecurringFlag] = useState(false);
    const [loadingConfirmation, setLoadingConfirmation] = useState(false);

    function fetchCards(cardsOrgId) {
        PaymentsService.getCards(cardsOrgId)
            .then((res) => {
                setCardsList(res.data.items);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const fetchCustomerAddress = (customerOrgId) => {
        PaymentsService.getCustomerAddress(customerOrgId)
            .then((res) => {
                setCustomerAddress(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setCustomerAddressLoader(false);
            });
    };

    useEffect(() => {
        fetchCards(orgId);
        fetchCustomerAddress(orgId);
    }, []);

    useEffect(() => {
        if (confirmationMsg) {
            updatePaymentState("confirmation");
        }
    }, [confirmationMsg]);

    useEffect(() => {
        setSelectedCard(cardList.find((item) => item.default === true));
    }, [cardList]);

    const { hide: hideAddCardDialog } = useModal();

    const { hide: hideBillingAddressDialog } = useModal();

    async function createPayment(targetOrgId, cardId, itemId, paymentItemType) {
        try {
            let res;
            const metadata = { ...(utmSource && { utmSource }) };
            if (paymentItemType === billingConstants.ITEM_TYPES.SUBSCRIPTION) {
                res = await PaymentsService.buySubscription(targetOrgId, {
                    planId: itemId,
                    paymentMethodId: cardId,
                    recurring: !nonRecurringFlag,
                    metadata: metadata,
                });
            } else if (paymentItemType === billingConstants.ITEM_TYPES.ADDON) {
                res = await PaymentsService.buyAddOn(targetOrgId, {
                    addonId: itemId,
                    paymentMethodId: cardId,
                    metadata: metadata,
                });
            } else {
                throw new Error("Invalid item type");
            }

            return res.data;
        } catch (error) {
            console.log("createPayment: ", { error });
            const errMsg =
                error?.response?.data?.message ||
                "We're unable to process your request at the moment";
            EventEmitter.dispatch(PAYMENT_FAILURE, {
                error,
                message: errMsg,
            });
            throw new Error(errMsg);
        }
    }

    const confirmPaymentWithRetry = (...args) => {
        return retry(
            async (bail) => {
                try {
                    const result = await stripe.confirmPayment(...args);

                    if (result.error?.code === "lock_timeout") {
                        return Promise.reject(result.error);
                    }

                    return result;
                } catch (err) {
                    bail(err);
                }
            },
            {
                retries: 5,
                factor: 1.5,
                minTimeout: 500,
            },
        );
    };

    const onSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }
        const credits = selectedItem.features?.credits?.quantity;
        const isYearly = selectedItem.interval === billingConstants.INTERVALS.YEARLY;
        EventEmitter.dispatch(BUY_BUTTON_CLICK, {
            amount: selectedItem.amount * (isYearly ? 12 : 1),
            credits,
        });
        setSubmitting(true);
        setPaymentMessage("Payment processing. Please refrain from closing this page. Thank you.");

        try {
            const {
                chargeCustomer,
                message,
                data: paymentObject,
            } = await createPayment(orgId, selectedCard._id, selectedItem._id, itemType);

            if (!chargeCustomer) {
                setSubmitting(false);
                return history.push(
                    `/organization/${orgId}/settings/billing/checkout/finish?defaultMessage=${message}` +
                        (redirectURL ? `&redirectUrl=${encodeURIComponent(redirectURL)}` : ""),
                );
            }

            const { error } = await confirmPaymentWithRetry({
                clientSecret: paymentObject.client_secret,
                confirmParams: {
                    return_url:
                        `${environment.HINATA_MAIN_DOMAIN}/organization/${orgId}/settings/billing/checkout/finish?stripeInvoiceId=${paymentObject.invoice}` +
                        (redirectURL ? `&redirectUrl=${encodeURIComponent(redirectURL)}` : ""),
                },
            });

            if (error) throw new Error(error.message);
        } catch (error) {
            setSubmitting(false);
            console.log("onSubmit: ", { error });
            const errMsg = error.message || "We're unable to process your request at the moment";
            EventEmitter.dispatch(PAYMENT_FAILURE, {
                error,
                message: errMsg,
            });
            toast.error(errMsg, {
                autoClose: 2500,
            });
        }
    };

    const changeSubscription = () => {
        setLoadingConfirmation(true);
        const metadata = { ...(utmSource && { utmSource }) };
        return PaymentsService.buySubscription(orgId, {
            planId: selectedItem._id,
            recurring: true,
            metadata: metadata,
        })
            .then((res) => {
                const { message } = res.data;
                toast.success(message || `Your subscription was changed successfully`, {
                    autoClose: 2000,
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error(
                    err?.response?.data?.message ||
                        "We are unable to change your subscription currently",
                );
            })
            .finally(() => {
                setLoadingConfirmation(false);
                history.push(`/organization/${orgId}/settings/billing`);
            });
    };

    const handleBackButtonState = (step) => {
        switch (step) {
            case "showCardDetails":
                return () => updatePaymentState("billingAddress");
            case "updateCardDetails":
                return () =>
                    updatePaymentState(cardList?.length ? "showCardDetails" : "billingAddress");
            case "billingAddress":
            default:
                return () => history.push(`/organization/${orgId}/settings/billing/pricing`);
        }
    };

    const renderForm = (step) => {
        switch (step) {
            case "confirmation":
                return (
                    <BoxLoader
                        stretch={true}
                        opacity={"10"}
                        zIndex={99}
                        loading={loadingConfirmation}
                    >
                        <ConfirmationWrapper>
                            <ConfirmationDesc>{confirmationMsg}</ConfirmationDesc>
                            <div>Do you wish to proceed with this change?</div>
                            <ConfirmationActionBar>
                                <Button width="100%" onClick={changeSubscription}>
                                    Update Subscription
                                </Button>
                                <Button
                                    mode="secondary"
                                    width="100%"
                                    onClick={() =>
                                        history.push(
                                            `/organization/${orgId}/settings/billing/pricing`,
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </ConfirmationActionBar>
                        </ConfirmationWrapper>
                    </BoxLoader>
                );

            case "showCardDetails":
                return (
                    <EachCard
                        cardList={cardList}
                        setSelectedCard={setSelectedCard}
                        selectedCard={selectedCard}
                        showAddCardDialog={() => {
                            updatePaymentState("updateCardDetails");
                        }}
                        customerAddress={customerAddress}
                        selectedItem={selectedItem}
                    />
                );
            case "updateCardDetails":
                return (
                    <AddNewCard
                        orgId={orgId}
                        refetchCards={() => {
                            hideAddCardDialog();
                            updatePaymentState("showCardDetails");
                            fetchCards(orgId);
                        }}
                        useOldCard={() => {
                            updatePaymentState("showCardDetails");
                        }}
                        setDefault={true}
                    />
                );

            case "billingAddress":
                return (
                    !loadingCustomerAddress && (
                        <BillingAddressForm
                            orgId={orgId}
                            hideBillingAddressDialog={(submitted) => {
                                hideBillingAddressDialog();
                                fetchCustomerAddress(orgId);
                                EventEmitter.dispatch(CHECKOUT_INITIATED, {
                                    checkOutInitiatedToBuy: selectedItem,
                                });
                                submitted &&
                                    updatePaymentState(
                                        cardList?.length ? "showCardDetails" : "updateCardDetails",
                                    );
                            }}
                            customerAddress={customerAddress}
                        />
                    )
                );
            default:
                break;
        }
    };

    const renderTitle = (step) => {
        let heading = "";
        switch (step) {
            case "confirmation":
                heading = "Confirm subscription update";
                break;
            case "showCardDetails":
                heading = "Payment Method";
                break;
            case "updateCardDetails":
                heading = "Add New Card";
                break;
            case "billingAddress":
            default:
                heading = customerAddress ? "Update Billing Address" : "Add Billing Address";
                break;
        }

        return (
            <Heading data-testid={`payment-flow-heading-${step}`}>
                <BackButton onClick={handleBackButtonState(step)} />
                {heading}
            </Heading>
        );
    };

    return (
        <>
            <BoxLoader
                stretch={true}
                opacity={"10"}
                zIndex={99}
                loading={submitting}
                message={paymentMessage}
            >
                <PageWrapper data-testid="checkout-page">
                    <BillingAddressAndCardDetailsWrapper isMobile={isMobile}>
                        <FormWrapper isMobile={isMobile}>
                            <DetailsWrapper>
                                {isMobile && (
                                    <ResponsiveLogo
                                        height={"auto !important"}
                                        width={"15rem"}
                                        isReponsive={false}
                                    />
                                )}
                                {renderTitle(paymentStep)}
                            </DetailsWrapper>
                            {renderForm(paymentStep)}
                            <DetailsWrapper>
                                {paymentStep === "showCardDetails" &&
                                    selectedCard &&
                                    customerAddress && (
                                        <Button
                                            role={`buy-${itemType}`}
                                            width="100%"
                                            onClick={onSubmit}
                                            disabled={
                                                selectedCard && customerAddress ? false : true
                                            }
                                        >
                                            Buy
                                        </Button>
                                    )}
                                {paymentStep === "showCardDetails" &&
                                    !(selectedCard && customerAddress) && (
                                        <p>Please add card to proceed for the payment</p>
                                    )}
                            </DetailsWrapper>
                        </FormWrapper>
                    </BillingAddressAndCardDetailsWrapper>
                    <PaymentDetailsWrapper>
                        <PaymentMobileWrapper>
                            <PaymentBreakDownWrapper
                                isMobile={isMobile}
                                data-testid="order-summary"
                            >
                                <DetailsWrapper>
                                    {!isMobile && (
                                        <ResponsiveLogo
                                            height={"auto"}
                                            width={"15rem"}
                                            isReponsive={false}
                                        />
                                    )}
                                    <HeadinWrapper>
                                        <Heading>Order Summary</Heading>
                                        {isMobile && (
                                            <BackButton
                                                onClick={() => {
                                                    toggleOrderView(!orderView);
                                                }}
                                                mode="unfilled"
                                                style={{
                                                    transform: `rotate(${
                                                        orderView ? "270deg" : "90deg"
                                                    })`,
                                                }}
                                            />
                                        )}
                                    </HeadinWrapper>
                                </DetailsWrapper>
                                {(!isMobile || orderView) && (
                                    <DetailsWrapper>
                                        {selectedItem && (
                                            <OrderSummaryWrapper>
                                                <StripeOrderDetails
                                                    selectedItem={selectedItem}
                                                    itemType={itemType}
                                                    selectedCard={selectedCard}
                                                />
                                            </OrderSummaryWrapper>
                                        )}
                                    </DetailsWrapper>
                                )}
                            </PaymentBreakDownWrapper>
                        </PaymentMobileWrapper>
                    </PaymentDetailsWrapper>
                </PageWrapper>
            </BoxLoader>
        </>
    );
};

export default StripeCheckoutPage;
