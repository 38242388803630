import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Breakpoints } from "../../../GlobalStyle";
import { useSelector } from "react-redux";
import pixelbinLogoMobile from "@common/assets/svgs/UpdatedPixelBinIconMobile.svg";
import pixelbinLogoDesktop from "@common/assets/svgs/UpdatedPixelBinIconDesktop.svg";

const StyledImage = styled.img`
    height: ${({ height }) => (height ? height : "32px")};
    width: ${({ width }) => (width ? width : "auto")};
    cursor: pointer;
    @media (max-width: ${Breakpoints.mobile}px) {
        height: 24px;
    }
`;
const ResponsiveLogo = ({ width, height, isRedirect, isReponsive = true }) => {
    const isMobile = useSelector((state) => state.userAgentDetails.isMobile);

    let url = isMobile && isReponsive ? pixelbinLogoMobile : pixelbinLogoDesktop;

    return (
        <>
            {isRedirect ? (
                <Link to="/">
                    <StyledImage width={width} height={height} alt={"logo"} src={url} />
                </Link>
            ) : (
                <StyledImage width={width} height={height} alt={"logo"} src={url} />
            )}
        </>
    );
};

export default ResponsiveLogo;
