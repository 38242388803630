import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Draggable from "react-draggable";
import { Body1 } from "../global-styled-components";
import EventEmitter from "../events";
import { ReportIssue as ReportIssueEvent, ReportIssueClosed } from "../events/events";
import BugIcon from "common/assets/svgs/BugIcon.svg";
import Tooltip from "./Tooltip";
import { setReportIssue } from "../redux/actions/useragentAction";
import { useLocation } from "react-router-dom";
import { visibility } from "../constants/reportIssue.Constants";
const ReportIssueWrapper = styled(Body1)`
    background: var(--primary-40);
    position: fixed;
    bottom: 0;
    left: 32px;
    cursor: move;
    max-width: 215px;
    border-radius: 2px;
    padding: 10px;
    margin: auto;
    user-select: none;
    font-weight: bold;
    z-index: 99999;
    color: var(--white);
`;
const Icon = styled.img``;
const ReportIssueHeader = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: var(--border-radius-8);
    justify-content: center;
    cursor: pointer;
    :hover {
        background-color: var(--gray-10);
    }
`;

const ReportIssueDropdown = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`;

const ReportIssue = ({ type, ...restProps }) => {
    const dragRef = React.useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [isComponentVisible, setIsComponentVisible] = useState(false);
    const location = useLocation();
    const checker = (path) => {
        for (const value of visibility) {
            if (path.includes(value)) {
                return true;
            }
        }
    };
    useEffect(() => {
        EventEmitter.on(ReportIssueClosed, function () {
            setReportIssue(
                {
                    isReportIssueClicked: false,
                },
                dispatch,
            );
        });
    }, []);

    useEffect(() => {
        if (checker(location.pathname)) setIsComponentVisible(true);
        else setIsComponentVisible(false);
    }, [location.pathname]);
    const handleDrag = (e) => {
        e.stopPropagation();
        setIsDragging(true);
        if (dragRef?.current) {
            dragRef.current.style.pointerEvents = "none";
        }
    };
    const onDragStop = () => {
        if (!isDragging) {
            EventEmitter.dispatch(ReportIssueEvent, { page: window?.location?.pathname });
        }
        if (dragRef?.current) {
            dragRef.current.style.pointerEvents = "auto";
        }
        setIsDragging(false);
    };
    const dispatch = useDispatch();
    const handleReportIssue = (e) => {
        e.stopPropagation();
        setReportIssue(
            {
                isReportIssueClicked: true,
            },
            dispatch,
        );
        EventEmitter.dispatch(ReportIssueEvent, { page: window?.location?.pathname });
    };

    switch (type) {
        case "header":
            return (
                <ReportIssueHeader
                    data-testid="reportIssueBtn"
                    {...restProps}
                    onClick={handleReportIssue}
                    onMouseDown={handleReportIssue}
                    onTouchStart={handleReportIssue}
                    data-for="bug-tooltip"
                    data-tip
                >
                    <Icon src={BugIcon} />
                    <Tooltip place="bottom" effect="solid" id="bug-tooltip">
                        Report Issue
                    </Tooltip>
                </ReportIssueHeader>
            );
        case "dropdown":
            return (
                <ReportIssueDropdown
                    data-testid="reportIssueBtn"
                    {...restProps}
                    onClick={handleReportIssue}
                    onMouseDown={handleReportIssue}
                    onTouchStart={handleReportIssue}
                >
                    <Icon data-for="bug-tooltip" data-tip src={BugIcon} />
                    <span>Report Issue</span>
                </ReportIssueDropdown>
            );
        default:
            return (
                <>
                    {isComponentVisible && (
                        <Draggable
                            onDrag={handleDrag}
                            onStop={onDragStop}
                            axis="x"
                            nodeRef={dragRef}
                            bounds="parent"
                        >
                            <ReportIssueWrapper
                                data-testid="reportIssueBtn"
                                ref={dragRef}
                                onClick={handleReportIssue}
                            >
                                Report Issue
                            </ReportIssueWrapper>
                        </Draggable>
                    )}
                </>
            );
    }
};

export default ReportIssue;
