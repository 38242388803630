import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

const TooltipWrapper = styled.div`
    .__react_component_tooltip {
        z-index: ${({ zIndex }) => zIndex || 999};
    }
`;

const StyledReactTooltip = styled(ReactTooltip)`
    font-style: var(--body-font) !important;
    font-size: 14px !important;
    padding: 8px !important;
    line-height: 20px !important;
    height: auto !important;
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth} !important` : "250px !important")};
    word-wrap: break-word;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
    ${(props) =>
        props.noArrow &&
        `
        ::before,
        ::after {
            content: none !important;
        }
    `}
`;

export default function Tooltip({ children, type = "light", enableToolTip = true, ...restProps }) {
    let textColor = "var(--text-default)";
    let bgColor = type === "faint" ? "disabled" : type;

    switch (bgColor) {
        case "white":
        case "light":
        case "success":
        case "info":
        case "warning":
            textColor = "text-default";
            bgColor = "surface-0";
            break;
        case "error":
            bgColor = "upload-bg-error";
            break;
    }

    return (
        enableToolTip && (
            <TooltipWrapper {...restProps}>
                <StyledReactTooltip
                    backgroundColor={`var(--${bgColor})`}
                    textColor={`var(--${textColor})`}
                    {...restProps}
                >
                    {children}
                </StyledReactTooltip>
            </TooltipWrapper>
        )
    );
}
