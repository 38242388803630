import React from "react";
import { ReactComponent as AngleLeft } from "@common/assets/svgs/Angle Left.svg";
import IconButton from "./IconButton";

const BackButton = (props) => {
    return (
        <IconButton mode="filled" {...props}>
            <AngleLeft />
        </IconButton>
    );
};

export default BackButton;
