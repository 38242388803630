import React from "react";

const ErrorIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            {...props}
        >
            <g id="ic_warning">
                <path
                    id="Vector"
                    d="M13.9997 21.0002C14.3497 21.0002 14.583 20.8835 14.8163 20.6502C15.0497 20.4168 15.1663 20.1835 15.1663 19.8335C15.1663 19.4835 15.0497 19.2502 14.8163 19.0168C14.583 18.7835 14.3497 18.6668 13.9997 18.6668C13.6497 18.6668 13.4163 18.7835 13.183 19.0168C12.9497 19.2502 12.833 19.4835 12.833 19.8335C12.833 20.1835 12.9497 20.4168 13.183 20.6502C13.4163 20.8835 13.6497 21.0002 13.9997 21.0002ZM13.9997 16.3335C14.3497 16.3335 14.583 16.2168 14.8163 15.9835C15.0497 15.7502 15.1663 15.5168 15.1663 15.1668V8.16683C15.1663 7.81683 15.0497 7.5835 14.8163 7.35016C14.583 7.11683 14.3497 7.00016 13.9997 7.00016C13.6497 7.00016 13.4163 7.11683 13.183 7.35016C12.9497 7.5835 12.833 7.81683 12.833 8.16683V15.1668C12.833 15.5168 12.9497 15.7502 13.183 15.9835C13.4163 16.2168 13.6497 16.3335 13.9997 16.3335ZM13.9997 25.6668C12.3663 25.6668 10.8497 25.3168 9.44967 24.7335C8.04967 24.1502 6.76634 23.3335 5.71634 22.2835C4.66634 21.2335 3.84967 19.9502 3.26634 18.5502C2.68301 17.1502 2.33301 15.6335 2.33301 14.0002C2.33301 12.3668 2.68301 10.8502 3.26634 9.45016C3.84967 8.05016 4.66634 6.76683 5.71634 5.71683C6.76634 4.66683 8.04967 3.85016 9.44967 3.26683C10.8497 2.6835 12.3663 2.3335 13.9997 2.3335C15.633 2.3335 17.1497 2.6835 18.5497 3.26683C19.9497 3.85016 21.233 4.66683 22.283 5.71683C23.333 6.76683 24.1497 8.05016 24.733 9.45016C25.3163 10.8502 25.6663 12.3668 25.6663 14.0002C25.6663 15.6335 25.3163 17.1502 24.733 18.5502C24.1497 19.9502 23.333 21.2335 22.283 22.2835C21.233 23.3335 19.9497 24.1502 18.5497 24.7335C17.1497 25.3168 15.633 25.6668 13.9997 25.6668Z"
                    fill="#660014"
                />
            </g>
        </svg>
    );
};

export default ErrorIcon;
