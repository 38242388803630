import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "@common/components/Button";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import TextInput from "common/components/TextInput";
import PaymentsService from "services/payments.service";
import CustomDropdown from "common/components/CustomDropdown";
import { billingConstants } from "./constants";
import { COUNTRIES_V2, getStatesInCountry } from "@common/constants/index";
import { zipCodeValidation } from "./utils";
import { StyledError, StyledErrorSkeleton } from "@common/global-styled-components";
import BoxLoader from "@common/components/BoxLoader";
import { isEmpty, omitBy } from "lodash";
const FormWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: ${({ title }) => (title ? "var(--surface-0)" : "")};
    border-radius: 8px;
    max-height: 600px;
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 25px 0px 25px;
    width: 100%;
    overflow-y: auto;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.marginBottom};
    margin-top: ${(props) => props.marginTop};
`;

const Label = styled.label`
    font-size: var(--body-3-d);
    font-weight: var(--font-weight-400);
    color: var(--text-default);
    width: 100%;
    margin-bottom: 10px;
`;

const Heading = styled.div`
    font-size: var(--subtitle-2-d);
    font-weight: var(--font-weight-600);
    padding: 25px;
    color: var(--text-default);
`;

const ButtonWrapper = styled.div`
    margin: 20px 25px;
`;

const BillingAddressForm = ({ orgId, hideBillingAddressDialog, customerAddress, title }) => {
    const [submitting, setSubmitting] = useState(false);
    const [isInr, setIsInr] = useState(false);
    const [enableStateDropdown, setEnableStateDropdown] = useState(false);
    const [states, setStates] = useState([]);

    const reactFormState = useForm({ mode: "all", defaultValues: customerAddress });

    const validation = {
        pattern: /^(?!\s*$)[a-zA-Z0-9\s.,#&'@()/_-]{1,100}$/,
    };

    const {
        control,
        register,
        handleSubmit,
        formState: { isDirty, errors },
        reset,
        watch,
        setValue,
    } = reactFormState;

    const allValues = watch();

    useEffect(() => {
        if (allValues.country?.code === billingConstants.COUNTRY_CODES.IN) {
            !isInr && setIsInr(true);
        } else {
            isInr && setIsInr(false);
        }

        if (
            [billingConstants.COUNTRY_CODES.IN, billingConstants.COUNTRY_CODES.US].includes(
                allValues.country?.code,
            )
        ) {
            !enableStateDropdown && setEnableStateDropdown(true);
        } else {
            enableStateDropdown && setEnableStateDropdown(false);
        }
    }, [allValues.country?.code]);

    const onSubmit = async (data) => {
        setSubmitting(true);
        let submitted = false;
        try {
            const metadata = {};
            if (data.gstNo) {
                metadata.gstNo = data.gstNo;
            }
            if (data.pan) {
                metadata.pan = data.pan;
            }

            const payload = omitBy(
                {
                    addressLine1: data.address,
                    addressCity: data.city,
                    addressState: enableStateDropdown ? data.stateDropdown : data.state,
                    addressCountry: data.country.code,
                    addressPostalCode: data.zipcode,
                    metadata,
                },
                isEmpty,
            );

            if (customerAddress) {
                await PaymentsService.updateBillingAddress(orgId, {
                    customerAddressId: +customerAddress._id,
                    ...payload,
                });
                isDirty && toast.success("Address updated");
            } else {
                await PaymentsService.saveBillingAddress(orgId, payload);
                isDirty && toast.success("Address added");
            }
            submitted = true;
        } catch (err) {
            console.log(err);
            toast.error(
                err?.response?.data?.message ||
                    "We're unable to process your request at the moment",
                { autoClose: 2500 },
            );
        }

        setSubmitting(false);
        hideBillingAddressDialog(submitted);
    };

    useEffect(() => {
        const fetchStates = async () => {
            const fetchedStates = await getStatesInCountry(allValues?.country?.code);
            setStates(fetchedStates);
        };
        if (allValues?.country?.code) fetchStates();
    }, [allValues?.country?.code]);

    return (
        <BoxLoader stretch={true} opacity={"10"} zIndex={99} loading={submitting}>
            <form onSubmit={handleSubmit(onSubmit)} data-testid="add-address-form">
                <FormWrapper {...{ title }}>
                    {title && <Heading>{title}</Heading>}
                    <DetailsWrapper>
                        <InputWrapper marginBottom="10px">
                            <TextInput
                                data-testid="address"
                                name="address"
                                label="address"
                                style={errors.address ? { borderColor: "red" } : {}}
                                id="address"
                                inputLabel="Address"
                                defaultValue={customerAddress?.addressLine1 || ""}
                                validation={{
                                    maxLength: 255,
                                    ...validation,
                                }}
                                register={register}
                                type="text"
                                placeholder="Address"
                                paddingRight="40px"
                            ></TextInput>
                            <StyledErrorSkeleton>
                                <StyledError>
                                    {errors.address?.type === "required"
                                        ? "Address is required"
                                        : errors.address?.type === "maxLength"
                                        ? "Address cannot be more than 255 characters long"
                                        : errors.address?.type === "pattern"
                                        ? "Address should be valid"
                                        : ""}
                                </StyledError>
                            </StyledErrorSkeleton>
                        </InputWrapper>

                        <InputWrapper marginBottom="10px">
                            <TextInput
                                data-testid="city"
                                name="city"
                                label="city"
                                id="city"
                                inputLabel="City"
                                defaultValue={customerAddress?.addressCity || ""}
                                validation={{
                                    maxLength: 255,
                                    ...validation,
                                }}
                                register={register}
                                type="text"
                                placeholder="City"
                                paddingRight="40px"
                            ></TextInput>
                            <StyledErrorSkeleton>
                                <StyledError>
                                    {errors.city?.type === "required"
                                        ? "City is required"
                                        : errors.city?.type === "maxLength"
                                        ? "City cannot be more than 255 characters long"
                                        : errors.city?.type === "pattern"
                                        ? "City should be valid"
                                        : ""}
                                </StyledError>
                            </StyledErrorSkeleton>
                        </InputWrapper>

                        <InputWrapper marginBottom="10px">
                            <Label>Country *</Label>
                            <Controller
                                control={control}
                                name="country"
                                defaultValue={customerAddress?.addressCountry}
                                rules={{ required: true }}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <CustomDropdown
                                        onBlur={onBlur}
                                        placeholder="Country"
                                        value={(() => {
                                            for (const country of COUNTRIES_V2) {
                                                if (
                                                    country.value.code === value ||
                                                    country.value.code === value?.code
                                                )
                                                    return country;
                                            }
                                        })()}
                                        onChange={(e) => {
                                            reset({
                                                ...allValues,
                                                state: "",
                                                stateDropdown: "",
                                                zipcode: "",
                                            });
                                            setValue("country", e.value);
                                            return onChange(e.value);
                                        }}
                                        className={`react-select-container`}
                                        classNamePrefix={`react-select`}
                                        options={COUNTRIES_V2}
                                        isSearchable={true}
                                    />
                                )}
                            />
                            <StyledErrorSkeleton>
                                {errors.country && errors.country?.type === "required" && (
                                    <StyledError role="error">Country is required</StyledError>
                                )}
                                {!errors.country && <StyledError></StyledError>}
                            </StyledErrorSkeleton>
                        </InputWrapper>

                        {enableStateDropdown ? (
                            <InputWrapper marginBottom="10px">
                                <Label>State</Label>
                                <Controller
                                    control={control}
                                    name="stateDropdown"
                                    defaultValue={customerAddress?.addressState}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <CustomDropdown
                                            onBlur={onBlur}
                                            placeholder="State"
                                            value={states.find((state) => state.value === value)}
                                            onChange={(e) => {
                                                setValue("stateDropdown", e.value);
                                                return onChange(e.value);
                                            }}
                                            className={`react-select-container`}
                                            classNamePrefix={`react-select`}
                                            options={states}
                                            isSearchable={true}
                                        />
                                    )}
                                />
                                <StyledErrorSkeleton>
                                    {errors.stateDropdown &&
                                        errors.stateDropdown?.type === "required" && (
                                            <StyledError role="error">
                                                State is required
                                            </StyledError>
                                        )}
                                    {!errors.stateDropdown && <StyledError></StyledError>}
                                </StyledErrorSkeleton>
                            </InputWrapper>
                        ) : (
                            <InputWrapper marginBottom="10px">
                                <TextInput
                                    data-testid="state"
                                    name="state"
                                    label="state"
                                    id="state"
                                    inputLabel="State"
                                    validation={{
                                        maxLength: 255,
                                        ...validation,
                                    }}
                                    register={register}
                                    defaultValue={customerAddress?.addressState || allValues?.state}
                                    type="text"
                                    placeholder="State"
                                    paddingRight="40px"
                                ></TextInput>
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors.state?.type === "required"
                                            ? "State is required"
                                            : errors.state?.type === "maxLength"
                                            ? "State cannot be more than 255 characters long"
                                            : errors.state?.type === "pattern"
                                            ? "State name should be valid"
                                            : ""}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            </InputWrapper>
                        )}

                        <InputWrapper marginBottom="10px">
                            <TextInput
                                data-testid="zipcode"
                                name="zipcode"
                                label="zipcode"
                                id="zipcode"
                                inputLabel="Postal Code"
                                validation={{
                                    validate: (v) => zipCodeValidation(v, allValues?.country?.code),
                                    ...validation,
                                    maxLength: 255,
                                }}
                                register={register}
                                type="text"
                                placeholder="Postal Code"
                                paddingRight="40px"
                                defaultValue={
                                    customerAddress?.addressPostalCode || allValues?.zipcode
                                }
                            ></TextInput>
                            <StyledErrorSkeleton>
                                {errors.zipcode && errors.zipcode?.type === "required" && (
                                    <StyledError role="error">Postal Code is required</StyledError>
                                )}
                                {errors?.zipcode?.type === "validate" && (
                                    <StyledError role="error">Postal Code is invalid</StyledError>
                                )}
                                {errors?.zipcode?.type === "pattern" && (
                                    <StyledError role="error">
                                        Postal Code should be valid
                                    </StyledError>
                                )}
                                {errors.zipcode?.type === "maxLength" && (
                                    <StyledError role="error">
                                        Postal Code cannot be more than 255 characters long
                                    </StyledError>
                                )}
                                {!errors.zipcode && <StyledError></StyledError>}
                            </StyledErrorSkeleton>
                        </InputWrapper>
                    </DetailsWrapper>
                    <ButtonWrapper>
                        <Button width="100%" onClick={handleSubmit} role="add-address">
                            {customerAddress ? "Save" : "Add address"}
                        </Button>
                    </ButtonWrapper>
                </FormWrapper>
            </form>
        </BoxLoader>
    );
};

export default BillingAddressForm;
