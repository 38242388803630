import styled, { css } from "styled-components";
import { publicPropButtonThemes } from "./constants/theme.constants";
import { Breakpoints } from "@src/GlobalStyle";

export const H1 = styled.h1`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--bold);
    font-size: var(--h1-d);
    color: var(--text-default);
    letter-spacing: var(--letter-spacing-15N);
    line-height: var(--line-height-120);

    @media (max-width: 1024px) {
        font-size: 40px;
        line-height: 42px;
    }

    @media (max-width: 768px) {
        font-size: var(--h1-m);
    }
`;

export const H2 = styled.h2`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--bold);
    font-size: var(--h2-d);
    color: var(--text-default);
    letter-spacing: var(--letter-spacing-1N);
    line-height: var(--line-height-120);

    @media (max-width: 768px) {
        font-size: var(--h2-m);
    }
`;

export const H3 = styled.h3`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--semi-bold);
    font-size: var(--h3-m);
    color: var(--text-default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-32);

    @media (max-width: 768px) {
        font-size: var(--h3-m);
    }
`;

export const LeadBody = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--regular);
    font-size: var(--lead-body-d);
    line-height: var(--line-height-140);

    @media (max-width: 767px) {
        font-size: var(--lead-body-m);
    }
`;

export const SubTitle1 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--semi-bold);
    font-size: var(--subtitle-1-d);
    color: var(--text-default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-size: var(--subtitle-1-m);
    }
`;

export const SubTitle2 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--semi-bold);
    font-size: var(--subtitle-2-d);
    color: var(--text-default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-size: var(--subtitle-2-m);
    }
`;

export const SubTitle3 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--semi-bold);
    font-size: var(--subtitle-3-d);
    color: var(--text-default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-weight: var(--regular);
        font-size: var(--subtitle-3-m);
    }
`;

export const SubTitle4 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--regular);
    font-size: var(--subtitle-4-d);
    color: var(--text-default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-size: var(--subtitle-4-m);
    }
`;

export const Body1 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--regular);
    font-size: var(--body-1-d);
    color: var(--text-default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-size: var(--body-1-m);
    }
`;

export const Body2 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--regular);
    font-size: var(--body-2-d);
    color: var(--text-default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-size: var(--body-2-m);
    }
`;

export const Body3 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--regular);
    font-size: var(--body-3-d);
    color: var(--text-default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-size: var(--body-3-m);
        letter-spacing: var(--letter-spacing-1);
    }
`;

export const Span2 = styled.span`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--regular);
    font-size: var(--body-2-d);
    color: var(--text-subdued-1);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-size: var(--body-2-m);
    }
`;

export const Caption1 = styled.p`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: normal;
    font-size: var(--caption-1-d);
    color: var(--text-subdued-1);
    /* letter-spacing: var(--caption-1-letter-spacing-d); */
    line-height: var(--line-height-22);

    @media (max-width: 768px) {
        font-size: var(--caption-1-m);
        /* letter-spacing: var(--caption-1-letter-spacing-m); */
        line-height: var(--line-height-16);
    }
`;

export const ContentDescr = styled.p`
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: var(--body-1-d);
    line-height: var(--line-height-28);
    color: var(--text-subdued-1);

    @media (max-width: 1024px) {
        font-size: var(--body-1-m);
        line-height: var(--line-height-28);
    }
`;

export const Container = styled.div`
    max-width: 1200px;
    padding: 0px 20px;
    margin: 0 auto;
`;

export const Button = styled.h2`
    font-family: var(--body-font);
    font-style: normal;
    font-weight: var(--semi-bold);
    font-size: var(--button-default-d);
    color: var(--white);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-120);
`;

export const ButtonSmall = styled.h2`
    font-family: var(--body-font);
    font-style: normal;
    font-weight: var(--semi-bold);
    font-size: var(--button-default-m);
    color: var(--white);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-120);
`;

export const BrandGradientText = css`
    background: var(--brand-gradient-erasebg);
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    -o-box-decoration-break: clone;
`;

export const BrandGradientTextReverse = css`
    background: var(--brand-gradient-reverse);
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    -o-box-decoration-break: clone;
`;

export const TextEllipsis = css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const PublicPropertiesColor = css`
    color: ${(p) =>
        p.theme.utmSource &&
        publicPropButtonThemes[p.theme.utmSource] &&
        `var(${publicPropButtonThemes[p.theme.utmSource].primary}) !important`};
`;

export const PublicPropertiesBorderColor = css`
    border: ${(p) =>
        p.theme.utmSource &&
        publicPropButtonThemes[p.theme.utmSource] &&
        `1px solid var(${publicPropButtonThemes[p.theme.utmSource].primary}) !important`};
`;

export const StyledErrorSkeleton = styled(Body3)`
    height: var(--body-2-d);
    margin-bottom: 10px;
`;

export const StyledError = styled.div`
    font-size: var(--body-4-d);
    color: var(--error);
    width: 100%;
    height: calc(var(--body-2-d) + var(--body-4-d));
`;

export const GlamArProductListGrid = styled.div`
    display: grid;
    grid-template-columns: ${({ columnData }) =>
        columnData.map((column) => column.width).join(" ")};
    gap: 10px;
    background-color: transparent;
    @media (max-width: ${Breakpoints.tablet}px) {
        grid-template-columns: ${({ columnDataTablet }) =>
            columnDataTablet.map((column) => column.width).join(" ")};
    }
`;

export const StyledLink = styled.a`
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    color: var(--primary-60);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: var(--line-height-25);
    transition: all 0.1s ease-in-out;

    :hover {
        color: var(--primary-50);
        text-decoration: underline;
    }
    :active {
        color: var(--primary-70);
        text-decoration: underline;
    }
    :disabled {
        opacity: 0.3;
    }
`;

export const GirdItemContainer = styled.div`
    min-height: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const BlurBackGround = styled.div`
    backdrop-filter: blur(25px);
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
`;
