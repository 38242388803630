import React from "react";
import styled from "styled-components";

const getBaseIconWrapperSize = ({ size }) => {
    switch (size) {
        case "default":
            return "48px";
        case "small":
            return "40px";
        default:
            return "56px";
    }
};

const BaseIconWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${getBaseIconWrapperSize};
    width: ${getBaseIconWrapperSize};
    background-color: transparent;
    border-radius: var(--border-radius-250);
    cursor: pointer;
    &:hover {
        &:not([disabled]) {
            path {
                ${({ isStroke }) => (isStroke ? "stroke" : "fill")}: var(--primary-50);
            }
        }
    }
    &:active {
        &:not([disabled]) {
            path {
                ${({ isStroke }) => (isStroke ? "stroke" : "fill")}: var(--primary-60);
            }
        }
    }
    &:disabled {
        opacity: 0.5;
        cursor: auto;
        pointer-events: none;
    }
    @media (max-width: 767px) {
        height: 40px;
        width: 40px;
    }
`;

const FilledIconWrapper = styled(BaseIconWrapper)`
    border: 1px solid var(--gray-40);
`;

const OutlinedIconWrapper = styled(BaseIconWrapper)`
    border: 1px solid var(--border-light);
`;

const IconButton = ({
    mode,
    size = "default", // default, small, large
    children,
    ...props
}) => {
    switch (mode) {
        case "filled":
            return (
                <FilledIconWrapper size={size} {...props}>
                    {children}
                </FilledIconWrapper>
            );
        case "outlined":
            return (
                <OutlinedIconWrapper size={size} {...props}>
                    {children}
                </OutlinedIconWrapper>
            );
        case "unfilled":
        default:
            return (
                <BaseIconWrapper size={size} {...props}>
                    {children}
                </BaseIconWrapper>
            );
    }
};

export default IconButton;
